import { Injectable } from '@angular/core';
import { SessionDataProvider } from '@providers/session-data/session-data';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { RedirectService } from '@providers/redirect/redirect.service';
import { UserManagerService } from '@providers/user-manager/user-manager.service';
import { StorageService } from '@providers/storage/storage.service';
import { StorageKeys } from '@enums/storage.enum';
import { Subscription, User } from '@models/entities/user';
import { CopnyManagmentService } from '@providers/copny-managment/copny-managment.service';
import { ConnectionManager } from '@providers/connection-manager/connection-manager';
import { FeatureFlagsService } from '@providers/feature-flags/feature-flags.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(
    private connection: ConnectionManager,
    private copnyManagment: CopnyManagmentService,
    private featureFlags: FeatureFlagsService,
    private redirect: RedirectService,
    private sessionData: SessionDataProvider,
    private storage: StorageService,
    private userService: UserManagerService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const [ user, token, tokenType, refresh, subscriptionIDP, subscriptionsIDP, companyData ]: any = await Promise.all(
      [
        this.storage.getMyData(StorageKeys.COOKIE_NAME),
        this.storage.getMyData(StorageKeys.TOKEN),
        this.storage.getMyData(StorageKeys.TOKEN_TYPE),
        this.storage.getMyData(StorageKeys.REFRESH_TOKEN_IDP),
        this.storage.getMyData(StorageKeys.SUBSCRIPTION_IDP),
        this.storage.getMyData(StorageKeys.SUBSCRIPTIONS_IDP),
        this.storage.getMyData(StorageKeys.COMPANY_DATA)
      ]
    );
    const { login } = route.data;
    const logout = route.queryParamMap.get('logout') === 'true';
    await this.featureFlags.init();

    switch (true) {
      case logout: {
        if (user || !!token) {
          await this.connection.logOut();
        }
        return login;
      }
      case !login && (!user || !token): {
        await this.connection.logOut();
        return false;
      }
      case login && user && !!token: {
        this.setSessionData(token, tokenType, refresh, subscriptionIDP, subscriptionsIDP, user);
        this.copnyManagment.company = companyData;
        if (this.sessionData.isLogged) {
          this.redirect.redirectToInit();
        }
        return !this.sessionData.isLogged;
      }
      default: {
        if (!!token && user) {
          this.setSessionData(token, tokenType, refresh, subscriptionIDP, subscriptionsIDP, user);
          this.copnyManagment.company = companyData;
        }

        if (this.sessionData.isLogged && !login) {
          this.sessionData.setSctLink();
        } else if (this.sessionData.isLogged && login) {
          this.redirect.redirectToInit();
        }
        return ( (this.sessionData.isLogged && !login) || (!this.sessionData.isLogged && login));
      }
    }
  }

  setSessionData(token: string, tokenType: string, refresh: string, subscription: Subscription, subscriptions: Subscription[], user: User): void {
    this.sessionData.token = token;
    this.sessionData.tokenType = tokenType;
    this.sessionData.refreshTokenIdp = refresh;
    this.sessionData.subscriptionIdp = subscription;
    this.sessionData.subscriptionsIdp = subscriptions;
    this.sessionData.isLogged = true;
    this.userService.currentUser = user;
  }
}
