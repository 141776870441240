export enum StorageKeys {
    TOKEN = 'token',
    TOKEN_TYPE = 'token_type',
    REFRESH_TOKEN_IDP = 'refreshTokenIdp',
    SUBSCRIPTION_IDP = 'subscriptionIdp',
    SUBSCRIPTIONS_IDP = 'subscriptionsIdp',
    COOKIE_NAME = 'PasserAdminUser',
    CURRENT_LANGUAGE = 'language',
    COMPANY_DATA = 'companyData',
    dataTicketsCached = 'dataTicketsCached',
    retryLogin = 'retryLogin',
    retryOpenCash = 'retryOpenCash',
    retryCancelTicket = 'retryCancelTicket',
    retryDiscount = 'retryDiscount'
};
