import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FfStatus } from '@enums/network.enum';
import { environment } from '@environments/environment';
import { FeatureFlagStatus } from '@enums/logistic.enum';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsService {
  private _featureFlags: any;
  private _ffStatus: string;

  private ffStatusSubject = new BehaviorSubject<string>(FfStatus.loading);
  ffStatus$ = this.ffStatusSubject.asObservable();

  async init() {
    try {
      const response = await fetch(
        `https://featureflagsapi.passer.live/api/features/${environment.ffKey}`
      );
      const responseObject = await response.json();
      this.featureFlags = responseObject.features;
      this.ffStatus = this.featureFlags?.length
        ? FfStatus.success
        : FfStatus.error;
      this.ffStatusSubject.next(this.ffStatus);
    } catch (e) {
      console.log('FF CATCH: ', e);
      this.ffStatus = FfStatus.error;
      this.ffStatusSubject.error(this.ffStatus);
    }
  }

  public get featureFlags(): any {
    return this._featureFlags;
  }

  public set featureFlags(value: any) {
    this._featureFlags = value;
  }

  public get ffStatus(): string {
    return this._ffStatus;
  }

  public set ffStatus(value: string) {
    this._ffStatus = value;
  }

  public evalFeature(feature: string): any {
    return this.featureFlags?.[feature]?.defaultValue;
  }

  async getStatus(): Promise<any> {
    return new Promise(resolve => {
      this.ffStatus$.subscribe(status => {
        if (status) {
          resolve(status);
        }
      });
    });
  }

  async isFeatureFlagPresent(name: string): Promise<string> {
    try {
      const isPresent = await this.evalFeature(name);
      return isPresent ? FeatureFlagStatus.success : FeatureFlagStatus.failed;
    } catch (e) {
      console.log('FF catch: ', e);
      return FeatureFlagStatus.error;
    }
  }
}
