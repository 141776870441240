export enum LoginEndpoints {
  loginToken = 'idp.passer.live/token',
  userInfo = 'idp.passer.live/me',
  idpRequest = 'idp.passer.live/api/providers/Passer.',
  sendCode = '/oauth/user/otp',
  verifyOtp = '/oauth//user/verifyOtp',
  getUserData = '/system/getUser',
  getCompanyData = '/system/loadSubscription'
};

export enum FfStatus {
  loading = 'loading',
  success = 'succes',
  error = 'error',
}
