export enum PackageTypeStatus {
  emptyData = 0,
  loadingData = 1,
  loadedData = 2,
  errorData = 3
}

export enum TypeLogistic {
  weight = 'weight',
  volumetry = 'volumetry',
  fixedRate = 'fixed-rate'
};

export enum TabManageSending {
  manage = 'manage',
  closing = 'closing'
};

export enum LogisticState {
  inSquare = 1,
  sent = 2,
  received = 3,
  delivered = 4,
  cancelled = 5
};

export enum DetailsOption {
  receive = 0,
  delivery = 1
};

export enum FeatureFlagStatus {
  success = 'success',
  error = 'error',
  failed = 'failed'
}
