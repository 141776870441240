import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NormalModalComponent } from '@components/normal-modal/normal-modal';
import { SuccessModalComponent } from '@components/success/success.modal';
import { ModalSuccess } from '@models/entities/general/general';
import { TranslationService } from '@providers/translation/translation.service';


@Injectable({
  providedIn: 'root'
})
export class ModalService {

  dialogRefOpened: MatDialogRef<unknown, any>;
  modalData: Array<ModalSuccess> = [];
  modalConfig = {
    width: '100%',
    autoFocus: false,
    disableClose: true
  };

  constructor(
    private dialog: MatDialog,
    private translation: TranslationService
  ) { }

  reviewModalRep(title, message): boolean {
    return this.modalData.findIndex( data => data.tit === title && data.desc === message) === -1;
  }

  createModalData(succ, bot2, bot, tit, desc, opt, that, item, i, j, bol): void {
    this.modalData.push({succ, bot2, bot, tit, desc, opt, that, item, i, j, bol});
  }

  async showAlert(succ, bot2?, bot?, tit?, desc?, opt?, that?, item?, i?, j?, bol?): Promise<any> {
    const data = { succ, bot2, bot, tit, desc, opt, that, item, i, j, bol };
    if(this.reviewModalRep(tit, desc)){
      this.createModalData(succ, bot2, bot, tit, desc, opt, that, item, i, j, bol);
      if(!this.dialogRefOpened){
        this.dialogRefOpened = this.dialog.open(SuccessModalComponent, {...this.modalConfig, data});
        return await this.afterCloseModal();
      }
    }
  }

  async afterCloseModal(): Promise<any> {
    const result = await this.dialogRefOpened.afterClosed().toPromise();
    this.dialogRefOpened = null;
    this.modalData.shift();
    if(this.modalData.length > 0){
      this.dialogRefOpened = this.dialog.open(SuccessModalComponent, {...this.modalConfig, data: this.modalData[0]});
      this.afterCloseModal();
    }
    return result;
  }

  showAlertComp(component: any, data: any): void {
    this.dialog.open(component, {...this.modalConfig, data});
  }

  async showAlertCompReturn(component: any, data: any, extraConfig?): Promise<any> {
    const dialogRef = this.dialog.open(component, {...this.modalConfig, ...extraConfig ,data});
    const result = await dialogRef.afterClosed().toPromise();
    return result;
  }

  showIncreasePlan(): void {
    const {
      alerts: { subscriptionBase },
      actions: { understood, noAction }
    } = this.translation.languageMessage;
    this.showAlertComp(NormalModalComponent, {
      title: noAction,
      message: subscriptionBase,
      btnSave: understood
    });
  }

  isModalPresent(id: string): boolean {
    return Boolean(this.dialog.getDialogById(id));
  }

  closeModal(): void { this.dialog.closeAll(); }
}
