import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { ROLES_ADMIN } from '@constants/roles';
import { ROUTES_ADMIN } from '@constants/routes';
import { FeatureFlagStatus } from '@enums/logistic.enum';
import { StorageKeys } from '@enums/storage.enum';
import { ConnectionManager } from '@providers/connection-manager/connection-manager';
import { FeatureFlagsService } from '@providers/feature-flags/feature-flags.service';
import { RedirectService } from '@providers/redirect/redirect.service';
import { RolesManagmentService } from '@providers/roles-managment/roles-managment.service';
import { StorageService } from '@providers/storage/storage.service';
import { UserManagerService } from '@providers/user-manager/user-manager.service';

@Injectable()
export class AuthGuardRolesService implements CanActivate {

  constructor(
    private connection: ConnectionManager,
    private featureFlagService: FeatureFlagsService,
    private redirect: RedirectService,
    private rolesService: RolesManagmentService,
    private storage: StorageService,
    private userService: UserManagerService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const { role } = route.data;
    try{
      const isLogged = await this.isAuthenticated(role);
      return isLogged;
    } catch(error) {
      this.connection.logOut();
      return false;
    }
  }

  async isAuthenticated(moduleName: string): Promise<boolean> {
    try {
      if (!this.userService.currentUser) {
        const currentUser = await this.storage.getMyData(StorageKeys.COOKIE_NAME);
        this.userService.currentUser = currentUser;
      }

      // TODO - privilege logistic has to be added to users for delete the if
      if (moduleName !== ROLES_ADMIN.LOGISTIC) {
        const hasAccess: boolean = this.userService.currentUser?.privileges.some(module => (module.name === moduleName));
        if (!hasAccess || !this.hasAccessPage(moduleName)) {
          this.redirect.redirectToInit();
          return false;
        }
      } else {
        const modulePresent = await this.featureFlagService.isFeatureFlagPresent('logistic');
        if (modulePresent === FeatureFlagStatus.failed) {
          this.redirect.redirectToInit();
          return false;
        }
      }
      return true;
    }
    catch (err) {
      this.connection.logOut();
      return false;
    }
  }

  hasAccessPage(moduleName: string): boolean {
    if (moduleName === ROLES_ADMIN.TICKETS && this.rolesService.onlySalesAndTickets && window.location.pathname === ROUTES_ADMIN.TICKETS_SUB_ROUTE.TICKETS_CONFIG) {
      return false;
    }
    if (moduleName === ROLES_ADMIN.ROLES && this.rolesService.onlyListRole && window.location.pathname === ROUTES_ADMIN.ROLES_SUB_ROUTE.ROLES_LIST_ROLES) {
      return false;
    }

    return true;
  }
}
